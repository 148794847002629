import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { SocialIcon } from 'react-social-icons';

//projects, skills, education, sns, interests, work

function App() {
  const [lang, setLang] = useState("en");
  const changeLang = () => {
    if(lang=="en"){
      setLang("jp");
    }else{
      setLang("en");
    }
  }
  const showLang = () => {
    if(lang=="en"){
      return(
        <>
        <p>JP/</p>
        <p className="text-lg font-bold">EN</p>
        </>
      )
    }else{
      return(
        <>
        <p className="text-lg font-bold">JP</p>
        <p>/EN</p>
        </>
      )
    }
  }
  const AUTO_SCROLL_SPEED = 1;  // Adjust this for faster/slower scroll
  const scrollingRef = useRef(null);
  const [isManualScroll, setIsManualScroll] = useState(false);

  useEffect(() => {
      let animationFrameId;

      const scrollContent = () => {
          if (!isManualScroll && scrollingRef.current) {
              scrollingRef.current.scrollLeft += AUTO_SCROLL_SPEED;

              // // If you've reached the end, reset to the start
              // if (scrollingRef.current.scrollLeft + scrollingRef.current.offsetWidth >= scrollingRef.current.scrollWidth) {
              //     scrollingRef.current.scrollLeft = 0;
              // }
          }

          animationFrameId = requestAnimationFrame(scrollContent);
      };

      // Start the animation loop
      animationFrameId = requestAnimationFrame(scrollContent);

      // On manual scroll, set the flag, and then clear it after a delay
      const handleManualScroll = () => {
          setIsManualScroll(true);
          setTimeout(() => setIsManualScroll(false), 200);
      };

      scrollingRef.current.addEventListener('wheel', handleManualScroll);

      return () => {
          cancelAnimationFrame(animationFrameId);
          scrollingRef.current.removeEventListener('wheel', handleManualScroll);
      };
  }, [isManualScroll]);

  return(
    <div className="App bg-base-100">
      <div className="fixed top-0 left-0 h-screen flex justify-center items-center text-[100px] w-screen">
        {/* <img className=' w-48' src='IMG_20231007_110519_460.jpg'/> */}
        {/* 🤌 */}
      </div>
      <div className="relative flex md:flex-row flex-col md:px-24 px-6">
        <div className='md:sticky md:top-0 md:max-h-screen md:py-20 py-12 justify-between flex flex-col md:w-1/2 text-left'>
          <div>
            <h1 className="md:text-6xl text-4xl font-sans font-bold text-white">
              Kazuya Hirotsu
            </h1>
            <h2 className="md:text-2xl text-xl text-white mt-3">
              Grad student at UTokyo and intern at OSARO
            </h2>
            <p className="mt-4">
              I'm working on building an AI robotics startup at the moment.
            </p>
          </div>
          <div className='flex flex-row mt-10'>
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' target="_blank" url="https://twitter.com/hirotsu_2" />
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' target="_blank" url="https://www.instagram.com/kazuyahirotsu/" />
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' target="_blank" url="https://www.linkedin.com/in/kazuyahirotsu" />
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' target="_blank" url="https://medium.com/@kazuyahirotsu" />
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' bgColor="#ff5a01" target="_blank" url="https://github.com/kazuyahirotsu" />
            <SocialIcon style={{ height: 40, width: 40 }} className='mx-1' target="_blank" url="mailto:hrtkzy4444@outlook.jp" />
          </div>
        </div>

        <div className='md:w-1/2 py-20'>
         <div className='mb-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Who am I?</p>
           <div className=''>
             <p className='md:text-xl'>Hi I'm Kazuya, a grad student at the University of Tokyo passionate about robotics and AI.</p>
             <p className='md:text-xl'>Outside tech, I enjoy photography, playing the violin and piano, and watching Formula 1.</p>
           </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Education</p>
           <div className='flex flex-col'>
            <div className='flex md:flex-row flex-col mb-10'>
              <p className='md:md:w-1/3 mt-1'>2023 APR - PRESENT</p>
              <div className='md:w-2/3'>
                <p><a className='text-white text-xl font-bold' href='https://www.t.u-tokyo.ac.jp/soe' target="_blank">the University of Tokyo</a></p>
                <p><a className='text-white text-xl font-bold' href='https://www.t.u-tokyo.ac.jp/soe' target="_blank">Graduate School of Engineering</a></p>
                <p className=''>I am currently pursuing a master's degree in engineering.</p>
                <p className=''>I am a member of <a className='link' href='https://www.nakao-lab.org/' target="_blank">Nakao Lab</a></p>
              </div>
            </div>
            <div className='flex md:flex-row flex-col mb-10'>
              <p className='md:w-1/3 mt-1'>2023 MAR - 2023 JUN</p>
              <div className='md:w-2/3'>
              <p><a className='text-white text-xl font-bold' href='https://www.unitn.it/en' target="_blank">Universita di Trento</a></p>
              <p><a className='text-white text-xl font-bold' href='https://www.unitn.it/en' target="_blank">Dipartimento di Ingegneria e Scienza dell’Informazione</a></p>
                <p className=''>I worked on developing a localization system for UAV with the support of Erasumus+ICM.</p>
                <p className=''>I was a member of <a className='link' href='https://www.granelli-lab.org/' target="_blank">Granelli Lab</a></p>
              </div>
            </div>
            <div className='flex md:flex-row flex-col'>
              <p className='md:w-1/3 mt-1'>2019 APR - 2023 MAR</p>
              <div className='md:w-2/3'>
              <p><a className='text-white text-xl font-bold' href='https://www.u-tokyo.ac.jp/en/' target="_blank">the University of Tokyo</a></p>
              <p><a className='text-white text-xl font-bold' href='https://www.u-tokyo.ac.jp/en/' target="_blank">Faculty of Engineering</a></p>
                <p className=''>I worked on developing an energy-efficient wireless relay communication system for IoT use and obtained a bachelor's degree in engineering.</p>
                <p className=''>I was a member of <a className='link' href='https://webpark2264.sakura.ne.jp/mizu/' target="_blank">Mizuno Lab</a></p>
              </div>
            </div>
           </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Work Experience</p>
           <div className='flex flex-col'>
            <div className='flex md:flex-row flex-col mb-10'>
              <p className='md:w-1/3 mt-1'>2023 OCT-2024 FEB</p>
              <div className='md:w-2/3'>
                <p><a className='text-white text-xl font-bold' href='https://osaro.com' target="_blank">Solutions Support Engineer / OSARO</a></p>
                <p className=''>full-time intern in San Francisco</p>
              </div>
            </div>
            <div className='flex md:flex-row flex-col mb-10'>
              <p className='md:w-1/3 mt-1'>2021 AUG-2023 SEP</p>
              <div className='md:w-2/3'>
                <p><a className='text-white text-xl font-bold' href='https://osaro.com' target="_blank">Solutions Support Engineer / OSARO</a></p>
                <p className=''>part-time intern in Tokyo/remote</p>
              </div>
            </div>
           </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Skills & Tools</p>
           <div className='mx-5'>
            <div ref={scrollingRef} className='flex flex-nowrap overflow-x-scroll'>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-python-plain text-7xl text-white"></i>
                <p>Python</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-pytorch-plain text-7xl text-white"></i>
                <p>Pytorch</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-java-plain text-7xl text-white"></i>
                <p>Java</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-javascript-plain text-7xl text-white"></i>
                <p>Javascript</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-react-plain text-7xl text-white"></i>
                <p>React</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-nextjs-original-wordmark text-7xl text-white"></i>
                <p>Next.js</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-tailwindcss-plain text-7xl text-white"></i>
                <p>tailwindcss</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-firebase-plain text-7xl text-white"></i>
                <p>firebase</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-flask-original text-7xl text-white"></i>
                <p>flask</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-googlecloud-plain text-7xl text-white"></i>
                <p>GCP</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-amazonwebservices-original text-7xl text-white"></i>
                <p>AWS</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-docker-plain text-7xl text-white"></i>
                <p>docker</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <img src='ros.png' className="w-16"/>
                <p className="w-16 mt-1">ROS</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-ubuntu-plain text-7xl text-white"></i>
                <p>Ubuntu</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-raspberrypi-line text-7xl text-white"></i>
                <p>RaspberryPi</p>
              </div>
              <div className='flex flex-col text-center m-1'>
                <i className="devicon-arduino-plain text-7xl text-white"></i>
                <p>Arduino</p>
              </div>
            </div>
           </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Scholarship & Grant</p>
           <div className='flex flex-col'>
            <div className='flex md:flex-row flex-col mb-10'>
                <p className='md:w-1/3 mt-1'>2024 APR - 2025 APR</p>
                <div className='md:w-2/3'>
                  <p><a className='text-white text-xl font-bold' href='https://www.nedo.go.jp/koubo/CA2_100437.html' target="_blank">NEDO Entrepreneurs Program / NEDO</a></p>
                  <p className=''>3,000,000 JPY </p>
                </div>
              </div>
              <div className='flex md:flex-row flex-col mb-10'>
                <p className='md:w-1/3 mt-1'>2023 MAR - 2023 JUN</p>
                <div className='md:w-2/3'>
                  <p><a className='text-white text-xl font-bold' href='https://international.unitn.it/incoming/icm-2020-information-for-partner-universities-and-mobility-participants-to-unitrento' target="_blank">Erasumus+ICM / EU</a></p>
                  <p className=''>€4,900 </p>
                </div>
              </div>
              <div className='flex md:flex-row flex-col mb-10'>
                <p className='md:w-1/3 mt-1'>2022 JUNE</p>
                <div className='md:w-2/3'>
                  <p><a className='text-white text-xl font-bold' href='https://www.keyence-foundation.or.jp/scholarship02/' target="_blank">Scholarship / KEYENCE FOUNDATION</a></p>
                  <p className=''>300,000 JPY</p>
                </div>
              </div>
            </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Publication</p>
            <div className='flex flex-col'>
              <div className='flex md:flex-row flex-col mb-10'>
                <p className='md:w-1/3 mt-1'>2023 MAR</p>
                <div className='md:w-2/3'>
                  <p><a className='text-white text-xl font-bold' href='https://ken.ieice.org/ken/paper/20230303aCSp/eng/' target="_blank">Proposal and Empirical Evaluation of Relay Communication Methods for LPWA Real-time Sensing</a></p>
                  <p className=''>K Hirotsu, K Mizuno, S Tabeta, T Shimada, Y Fujimoto, A Nakao IEICE Technical Report; IEICE Tech. Rep. 122 (406), 481-486</p>
                </div>
              </div>
            </div>
         </div>

         <div className='my-20 text-left'>
           <p className='md:text-5xl text-4xl text-primary md:mb-10 mb-5 sticky top-0'>Projects</p>
           <div className='flex flex-col'>
           <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='naisoai.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>AI home renovation assistant</p>
                <p>This service generates interior design based on your style and prompt request, and suggests real products you can buy to make it real.</p>
                <p>You can try this at <a className='link font-bold' href='https://naiso-ai.vercel.app/' target="_blank">naiso-ai.vercel.app/</a></p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">Tailwind</div>
                  <div className="badge badge-secondary badge-outline mr-2">Firebase</div>
                  <div className="badge badge-secondary badge-outline mr-2">Generative AI</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='monter.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>VR website building and hosting service</p>
                <p>This service let you make your own VR website easily. You can build 3D world with objects that you 3D scanned or modeled.</p>
                <p>You can try this at <a className='link font-bold' href='https://vrshop-100.web.app' target="_blank">monter.app</a></p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">Tailwind</div>
                  <div className="badge badge-secondary badge-outline mr-2">Firebase</div>
                  <div className="badge badge-secondary badge-outline mr-2">A-Frame</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='madoreader.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>AI floorplan scale analyis and VR preview</p>
                <p>You can find out the scale of your floorplan and get inside of an AI generated life-sized 3d model in VR. This app is using custom made segmentation AI model to detect occupied area and walls under Japanese law. We developed this for a hackathon called JPHACKS and won Jury's Special Award, Sponsor Awards from JLL, LANDNET, SAKURA internet.</p>
                <p>You can try a demo at <a className='link font-bold' href='http://oyster-ashy.vercel.app/' target="_blank">oyster-ashy.vercel.app</a></p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">Mantine</div>
                  <div className="badge badge-secondary badge-outline mr-2">Firebase</div>
                  <div className="badge badge-secondary badge-outline mr-2">GCP</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='serving.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>Serving order optimizer</p>
                <p>This is a service that uses a thermal camera to check the temperature of cooked food in the kitchen and determine which dishes should be served earlier, ensuring that food is served at the right temperature and is safe to eat. With this service, you can be confident that your dishes are cooked to perfection and served at the optimal temperature. We developed this for a hackathon called "UMP-JUST x Mitsubishi Electric hackathon" and won Audience Award and Best Tech Award.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">Mantine</div>
                  <div className="badge badge-secondary badge-outline mr-2">Firebase</div>
                  <div className="badge badge-secondary badge-outline mr-2">MelDIR</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='memoryboost.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>AI conversation management</p>
                <p>This app analyzes what you talked about and who was there from your video using face recognition and speech2text. You will no longer forget any details in conversations with your family, friends and business contacts.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">PyTorch</div>
                  <div className="badge badge-secondary badge-outline mr-2">Firebase</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='moreprojects.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>SNS for sharing projects</p>
                <p>You can share your personal projects and updates on this SNS.</p>
                <p>You can try this at <a className='link' href='https://geekbeaver.vercel.app/' target="_blank">moreprojects.dev</a></p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">React</div>
                  <div className="badge badge-secondary badge-outline mr-2">Next.js</div>
                  <div className="badge badge-secondary badge-outline mr-2">vercel</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>YouTube search by duration</p>
                <p>This is a web service where you can specify the duration when you search for YouTube videos. We made this app because YouTube only allows us to select from ~4, 4~20, 20~ minutes.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">Flask</div>
                  <div className="badge badge-secondary badge-outline mr-2">YouTube API</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='boat.png' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>Autonomous boat</p>
                <p>We made a boat that can autonomously detect and head toward AprilTag and connect to the dock with the gripper.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">ESP32</div>
                  <div className="badge badge-secondary badge-outline mr-2">Huskylens</div>
                  <div className="badge badge-secondary badge-outline mr-2">Blink</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>Remote controlled rover</p>
                <p>This rover can be controlled over the Internet with live video from onboard camera.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">RaspberryPi</div>
                  <div className="badge badge-secondary badge-outline mr-2">ROS</div>
                  <div className="badge badge-secondary badge-outline mr-2">Blink</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='ardurover.jpg' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>Autonomous rover</p>
                <p>This modified RC car can run on the specified route using GPS.</p>  
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">Ardurover</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
                <img className='' src='drone.jpg' />
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>Film camera on fpv drone</p>
                <p>I combined two of my hobbies. This drone let me shoot film photography from the sky.</p>
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">Fusion360</div>
                  <div className="badge badge-secondary badge-outline mr-2">Ender3</div>
                  <div className="badge badge-secondary badge-outline mr-2">betaflight</div>
                </div>
              </div>
            </div>
            <div className='flex md:flex-row flex-col-reverse mb-10'>
              <div className='md:w-1/3 w-2/3 md:mt-1 mt-3 pr-4'>
              </div>
              <div className='md:w-2/3'>
                <p className='text-white text-xl font-bold mb-2'>CO2 detector</p>
                <p>This CO2 detector can display the CO2 concentration and alart when it reaches the threshold.</p>   
                <div className='flex flex-row mt-2 flex-wrap'>
                  <div className="badge badge-secondary badge-outline mr-2">Arduino</div>
                </div>         
              </div>             
            </div> 
          </div>
        </div>
      </div>
    </div>
    </div>
  )

}

export default App;
